var _a;
import key from './key';
import loadable from '@loadable/component';
export default [
    {
        id: key.ID,
        reducer: function () { return import('./aikeCRM.reducer'); }
    },
    {
        point: '@@components',
        namespace: key.ID,
        onload: function () { return [{ key: 'AikeCRMView', component: function () { return import('./aike-view'); } }]; }
    },
    {
        point: '@@layers',
        prefix: '@aikeCRM',
        onload: function () { return import('./layers'); }
    },
    {
        resource: key.ID,
        value: (_a = {},
            _a['elements/MongolianLayer'] = loadable(function () { return import('./elements/MongolianLayer'); }),
            _a)
    }
];
